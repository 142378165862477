import '@/styles/scss/style.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import FeatherIcon from '@/hk-components/Icons/FeatherIcon.vue'
import simplebar from 'simplebar-vue'
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';
import HkDateRangePicker from "@/hk-components/@hk-daterangepicker/HkDateRangePicker.vue";

import HkRating from './hk-components/@hk-rating/HkRating.vue';

import { tooltipDirective } from '@/util/tooltip'



const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueSweetalert2);

//vue components
app.component('simplebar', simplebar);
app.component("apexchart", VueApexCharts);
app.component('feather-icon', FeatherIcon);
app.component('hk-rating', HkRating);  
app.component('DateRangePicker', HkDateRangePicker);

//vue directives
app.directive('tooltip', tooltipDirective)

app.mount('#app')
